import React from "react";
import Image from "next/image";
import absoluteUrl from "next-absolute-url";
import classNames from "classnames";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { useInView } from "react-intersection-observer";
import { useTranslation } from "next-i18next";
import {
  Layout,
  HeroSubText,
  Items,
  CategoryNav,
  WhatsAppQuickChat,
  OGMetaDataHead,
  ProductSearch,
} from "components";
import AuthService from "services/auth";
import StoreService from "services/store";
import useScrollStop from "hooks/useScrollStop";
import { asyncAwaitWrap } from "utils/helpers";
import { generateSubdomainFromLocale } from "utils/app";
import { setDefaultAuthHeader } from "utils/axios";

const Category = ({ category, store, origin }) => {
  const [isScrolling] = useScrollStop(false);
  const { ref: searchInputFloating, inView } = useInView();
  const { t: trans } = useTranslation("common");
  const storeNameCaseLower = store?.subdomain?.toLowerCase();

  return (
    <React.Fragment>
      <OGMetaDataHead store={store} origin={origin} page={"store"} />
      <Layout containerClass="page-container__home">
        <WhatsAppQuickChat />
        <div
          className={classNames(
            "search-container search-container__static fixed top-70 z-1",
            {
              hidden: isScrolling || inView,
            }
          )}
        >
          <ProductSearch />
        </div>
        <div className="relative">
          <div className="relative max-w-5xl mx-auto">
            <div className="flex flex-col min-h-screen lg:flex-row lg:items-center">
              <div className="flex flex-col flex-grow bg-white lg:overflow-hidden">
                <div className="p-6 bg-gray-900 text-white hidden">
                  <p className="text-center text-sm md:text-base">
                    Enjoy free delivery across the UAE, Free Gift wrapping
                    available
                  </p>
                </div>
                <div className="relative pb-2/3 text-center">
                  {category ? (
                    <Image
                      src={`https://media.zbooni.shop/assets/web/enterprise/${storeNameCaseLower}/hero-banner-${category}.png`}
                      alt={`banner-${storeNameCaseLower}-${category}`}
                      width={960}
                      height={640}
                      className="mx-auto"
                    />
                  ) : (
                    <Image
                      src={`https://media.zbooni.shop/assets/web/enterprise/${storeNameCaseLower}/hero-banner.png`}
                      alt={`banner-${storeNameCaseLower}`}
                      width={960}
                      height={640}
                      className="mx-auto"
                    />
                  )}
                </div>
                <div className="bg-white">
                  <div className="is-visible" ref={searchInputFloating}>
                    <div className="pt-2 search-container search-container__floating">
                      <ProductSearch />
                    </div>
                  </div>
                  <HeroSubText store={store} />
                  <CategoryNav />
                  <Items
                    store={store}
                    products={store.products}
                    additionalQueryParam={{ tags__slug__in: category }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </React.Fragment>
  );
};

export const getServerSideProps = async ({
  params: { category },
  locale,
  req,
  res,
}) => {
  const { data } = await AuthService.getToken();
  setDefaultAuthHeader(data.access_token);
  const { origin } = absoluteUrl(req);
  const subdomain = generateSubdomainFromLocale(locale);
  const [storeApiError, storeApiResponse] = await asyncAwaitWrap(
    StoreService.getStoreBySubdomain(subdomain)
  );
  if (storeApiError || !storeApiResponse.data.count) {
    return {
      notFound: true,
    };
  }
  const { data: storeObject } = storeApiResponse;
  return {
    props: {
      category,
      origin,
      store: storeObject.results?.[0],
      ...(await serverSideTranslations(locale, ["common"])),
    },
  };
};

export default Category;
